@use '/src/components/design-system/common/colors';
@use '/src/components/design-system/common/mixins';

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px 36px 18px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.06);

  .links {
    display: flex;
    gap: 36px;

    .linkText {
      text-transform: capitalize;
    }

    .link {
      @include mixins.linkHover(colors.$gray-500, colors.$gray-900);

      &.active {
        @include mixins.linkHover(colors.$gray-900, colors.$gray-900);

        .linkText {
          font-weight: 500;
        }
      }
    }
  }
}
