@use 'sass:math';
@use 'sass:map';
@import url('../common/fonts.scss');

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
}

$rem-size: 16;
$sizes: (8, 12) (12, 16) (14, 20) (16, 24) (18, 28) (20, 28) (24, 32) (30, 40) (72, 80);
$weights: ('Book', 400) ('Medium', 500) ('Bold', 700) ('Black', 900);

@function pxToRem($pxValue) {
  @return calc($pxValue / $rem-size) + rem;
}

@each $size in $sizes {
  .text-#{nth($size, 1)} {
    font-size: pxToRem(nth($size, 1));
    line-height: pxToRem(nth($size, 2));
    letter-spacing: .02rem;
  }
}

@each $weight in $weights {
  .text-weight-#{nth($weight, 2)} {
    font-family: 'Pragmatica-#{nth($weight, 1)}', arial;
    font-weight: nth($weight, 2);
  }
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}
