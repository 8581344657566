@use '/src/components/design-system/common/colors.scss';

.content {
  display: grid;
  grid-row-gap: 2px;
  grid-template-columns: repeat(2, 1fr);
  grid-template-areas:
    'a b'
    'c d'
    'e f'
    'g h'
    'fileUploader fileUploader';
  grid-column-gap: 20px;
  margin-top: 20px;

  &_doff {
    grid-template-areas:
      'a b'
      'c d'
      'fileUploader fileUploader';
  }

  &__fileUploader {
    grid-area: fileUploader;
  }

  &:last-child {
    margin-bottom: 0;
  }
}
