@use '/src/components/design-system/common/colors.scss';

.container {
	display: flex;
  
  .tabs {
    display: flex;
    position: relative;

    * {
      z-index: 2;
    }

    input[type="radio"] {
      display: none;
    }

    .glider {
      position: absolute;
      display: flex;
      height: 4px;
      bottom: 0;
      background-color: colors.$teal-600;
      z-index: 1;
      transition: transform 0.25s ease-out, width 0.2s ease-out;
      transition-delay: 0, 0.05s;
    }

    .tab {
      display: flex;
      align-items: center;
      justify-content: center;
      color: colors.$gray-400;
      cursor: pointer;
      transition: color 0.15s ease-in;
      padding: 18px 44px 14px 44px;

      &.active {
        color: colors.$gray-900;
      }
    }
  }
}
