@use '/src/components/design-system/common/colors.scss';

.table {
  width: 100%;
  position: relative;

  &__header {
    position: sticky;
    top: 0;
    background-color: colors.$light-bg;
    z-index: 1;

    &__cell {
      &__column {
        min-width: 300px;

        &:last-child {
          .table__header__cell__column__title {
            border-right: none;
          }

          .table__header__cell__column__container {
            &:last-child {
              border-right: none;
            }
          }
        }

        &__title {
          width: 100%;
          height: 33px;
          padding-top: 5px;
          border-right: 1px solid colors.$gray-300;
          text-transform: uppercase;
          user-select: none;
        }

        &__container {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          border-right: 1px solid colors.$gray-300;

          &__week {
            display: flex;
            position: relative;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 22px;
            border-right: 1px solid colors.$gray-300;
            border-top: 1px solid colors.$gray-300;

            &__day {
              display: flex;
              flex-direction: row;
              width: 25px;
              height: 22px;
              margin: 5;
            }

            &__text {
              position: absolute;
              display: flex;
              column-gap: 3px;
              flex-direction: row;
              justify-content: center;
              align-items: center;
            }

            &:last-child {
              border-right: none;
            }
          }
        }
      }
    }
  }

  &__body {
    &__row {
      border-bottom: 1px solid colors.$gray-300;

      &__cell {
        &__container {
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          &__week {
            display: flex;
            flex-direction: row;
            border-right: 1px solid colors.$gray-300;
            width: 100%;
            height: 30px;

            &__day {
              display: flex;
              flex-direction: row;
              width: 25px;
              height: 29px;

              &_missing {
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                border-radius: 4px;
                margin-top: 2px;
                height: 25px;
                padding: 3px;
                cursor: pointer;

                span {
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                }
              }
            }
          }
        }

        &:last-child {
          .table__body__row__cell__container__week {
            &:last-child {
              border-right: none;
            }
          }
        }
      }
    }

    :last-child {
      border-bottom: none;
    }
  }
}
