@use './colors.scss';

@mixin hover($color, $hoverColor) {
  color: $color;
  svg > * {
    fill: $color;
  }

  &:hover {
    color: $hoverColor;
    svg > * {
      fill: $hoverColor;
    }
  }
}

@mixin linkHover($color, $hoverColor) {
  a {
    @include hover($color, $hoverColor);
  }
}
