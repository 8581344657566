@use '/src/components/design-system/common/colors';

.container {
  height: 100%;
  display: flex;
  flex-direction: column;

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    flex: 0 1 auto;

    &__title {
      color: colors.$gray-900;
    }
  }

  &__table {
    flex: 1 1 auto;
    background-color: colors.$white;
    padding: 16px;
    border-radius: 10px;
    border: 1px solid colors.$gray-200;
    box-shadow: 0 2px 6px colors.$main-shadow;
    display: flex;
    flex-direction: column;

    &__header {
      display: flex;
      flex-wrap: wrap;
      gap: 16px;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 30px;

      &__buttons {
        display: flex;
        height: 38px;
        flex-direction: row;
        gap: 16px;

        &__dropdown {
          height: 38px;
        }
      }
    }
  }
}

.files {
  padding: 12px 0;
  display: flex;
  flex-direction: column;
  gap: 12px;

  &__container {
    padding: 4px;
    border: 1px solid colors.$gray-200;
    display: flex;
    align-items: center;
    border-radius: 4px;
    justify-content: space-between;

    &__name {
      display: flex;
      gap: 8px;
      align-items: center;

      span {
        max-width: 120px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    &__button {
      padding: 5px;

      span {
        color: colors.$blue-400;
      }
    }
  }
}

.modal {
  overflow: visible;
}
