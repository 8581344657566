@use '/src/components/design-system/common/colors.scss';

.container {
  overflow-y: auto;
  border: 1px solid colors.$gray-300;
  border-radius: 4px;

  &__table {
    display: flex;
    flex-direction: row;
    width: fit-content;
  }
}
