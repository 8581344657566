@use '../common/colors';

.container {
  width: 100%;

  &__files {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 8px;

    &__item {
      display: flex;
      align-items: center;
      gap: 4px;

      &__link {
        color: colors.$blue-600;
        max-width: 161px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &__remove {
        width: 16px;
        height: 16px;
        cursor: pointer;

        & > * {
          fill: colors.$gray-300;
        }
      }
    }
  }

  &__uploader {
    display: flex;
    gap: 16px;

    &__input {
      flex: 1 1 auto;
    }

    &__button {
      height: 42px;
    }
  }
}
