@use '/src/components/design-system/common/colors';

.tooltip {
  display: flex;
  width: max-content;
  align-items: center;
  justify-content: center;
  position: fixed;
  height: max-content;
  left: 60px;
  z-index: 20;
  border-radius: 4px;
  background-color: colors.$black;
  color: colors.$white;
  padding: 5px;

  span::first-letter {
    text-transform: uppercase;
  }

  div {
    span::first-letter {
      text-transform: uppercase;
    }
  }
}
