@use '../common/transitions';
@use '../common/colors';

.modalContainer {
  z-index: 10;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;

  .modalHeader {
    border-bottom: none;
    padding: 32px 32px 36px 32px;
  }

  .modalBody {
    padding: 0 32px 48px 32px;
  }

  .cardFooter {
    display: flex;
    gap: 12px;
    justify-content: flex-end;
  }

  .overlay {
    opacity: 1;
    width: 100%;
    height: 100%;
    background-color: colors.$gray-600-60;
    transition: opacity 0.5s;
    position: fixed;
    top: 0;
    left: 0;
    cursor: pointer;
  }

  .closeModalHeader {
    position: relative;

    .subTitle {
      margin-top: 8px;
      color: colors.$gray-500;
    }

    .closeModal {
      width: 24px;
      height: 24px;
      position: absolute;
      top: 2px;
      right: 0;
      cursor: pointer;

      svg {
        stroke: colors.$gray-300;
      }
    }
  }

  .confirm {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 12;
    background-color: colors.$gray-600-60;

    &__container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background-color: colors.$gray-50;
      height: 120px;
      padding: 15px;
      border-radius: 10px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    &__buttons {
      display: flex;
      gap: 10px;
      flex-direction: row;
      justify-content: flex-end;
    }
  }
}

.modal {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-width: 660px;
  z-index: 11;
  overflow: auto;
  max-height: 80vh;
}
