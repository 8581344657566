@use '/src/components/design-system/common/colors';

.container {
  display: flex;
  flex-direction: column;

  &__info {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
    gap: 20px;

    &__text {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 5px;

      &_active {
        cursor: pointer;
      }
    }

    &__svg {
      transition: 0.3s;

      path {
        fill: colors.$gray-600;
      }

      &_open {
        transform: rotate(90deg);
      }

      &_disabled {
        path {
          fill: colors.$gray-200;
        }
      }
    }

    &__name {
      &__left {
        padding-left: 30px;
      }
    }

    &__actions {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 5px;

      svg {
        width: 22px;
        height: 22px;
        cursor: pointer;

        path {
          fill: colors.$gray-600;
        }

        &:hover {
          path {
            fill: colors.$gray-800;
          }
        }
      }
    }
  }

  &__tree {
    padding-left: 30px;
    margin-left: 12px;
    border-left: 1px dashed colors.$gray-400;
  }
}
