@use '/src/components/design-system/common/colors';

.accounting {
  height: 100%;
  display: flex;
  flex-direction: column;

  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    flex: 0 1 auto;

    .heading {
      color: colors.$gray-900;
    }

    .export {
      background-color: colors.$white;
    }
  }

  .body {
    flex: 1 1 auto;
    background-color: colors.$white;
    padding: 16px;
    border-radius: 10px;
    border: 1px solid colors.$gray-200;
    box-shadow: 0 2px 6px colors.$main-shadow;
    display: flex;
    flex-direction: column;

    .tableHeader {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 30px;

      .search {
        display: flex;
        height: 38px;
        flex-direction: row;
        gap: 16px;
        width: 100%;
        max-width: 750px;
        padding-right: 16px;

        .input {
          width: 100%;
          max-width: 450px;
        }
      }

      .button {
        height: 38px;
        min-width: 190px;
      }
    }
  }
}

.date {
  display: flex;
  gap: 4px;
  flex-wrap: wrap;

  &__experience {
    color: colors.$gray-400;
  }
}
