.datepickerBlock {
  display: flex;
  gap: 5px;
}

.subforms {
  display: flex;
  gap: 11px;
  align-items: flex-end;
  justify-content: center;

  .subForm {
    position: relative;
    max-width: 280px;

    &:nth-child(1)::after {
      display: none;
    }

    &::after {
      content: '';
      position: absolute;
      width: 1px;
      left: -6px;
      top: 0;
      height: 100%;
      background: #d1d5db;
    }

    .partCode {
      margin-bottom: 15px;
      text-align: center;
    }

    .inputs {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      column-gap: 5px;

      .input {
        width: 100px;
      }

      .select {
        width: 100px;
        label + span {
          min-width: 0;
          max-width: 50px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
  }
}
