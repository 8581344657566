@use '/src/components/design-system/common/colors';

.container {
  height: 100%;
  display: flex;
  flex-direction: column;

  &__title {
    margin-bottom: 20px;
  }

  &__info {
    flex: 1 1 auto;
    background-color: colors.$white;
    padding: 16px;
    border-radius: 10px;
    border: 1px solid colors.$gray-200;
    box-shadow: 0 2px 6px colors.$main-shadow;
    display: flex;
    flex-direction: column;

    &__button {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 30px;
    }
  }
}
