@use '/src/components/design-system/common/colors';

.content {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.dismissal {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.exit_interview {
  &__heading {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 20px;
    margin-bottom: 36px;
  }

  &__questionnaire {
    display: flex;
    flex-direction: column;
    gap: 32px;

    &__title {
      color: colors.$gray-600;
    }
  }
}