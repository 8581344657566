@use '../common/colors.scss';
@use '../common/mixins.scss';
@import url('../common/fonts.scss');

.container {
  position: relative;
  margin-bottom: 30px;

  .icon {
    position: absolute;
    height: 16px;
    pointer-events: none;
    top: 13px;

    svg {
      height: 16px;
      width: 16px;
    }

    &.prefixIcon {
      left: 13px;
    }

    &.suffixIcon {
      right: 13px;
    }
  }

  .errorMessage {
    color: colors.$red-600;
    position: absolute;
    top: 43px;
    left: 0;
  }

  .label {
    position: absolute;
    transform: translateY(-31px) translateX(7px);
    transition: transform 0.15s ease;
    color: colors.$gray-300;
    background-color: colors.$white;
    padding: 0 5px 0 5px;
    display: flex;
    gap: 4px;
    pointer-events: none;

    .placeholder,
    .required {
      transition: font-size 0.15s ease;
    }

    &.error {
      color: colors.$red-600;
    }

    &.right {
      transform: translateY(-31px) translateX(32px);
    }
  }

  .input {
    width: 100%;
    border: 1px solid colors.$gray-300;
    padding: 10px 12px 10px 12px;
    border-radius: 4px;
    background-color: colors.$white;
    font-family: Pragmatica-Book, arial;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.02rem;

    &:focus-visible {
      outline: none;
    }

    &::placeholder {
      transition: opacity 0.15s ease-out;
      color: colors.$gray-300;
    }

    &:hover {
      border-color: colors.$gray-400;
    }

    .notDisabled {
      &:active,
      &:focus {
        border-color: colors.$teal-600;

        ~ .icon svg > path {
          fill: colors.$teal-600;
        }
      }
    }

    &:focus,
    &.active,
    &.withValue {
      & + .label {
        color: colors.$teal-600;
        transform: translateY(-52px) translateX(7px);
        background-color: colors.$white;

        &.error {
          color: colors.$red-600;
        }

        .required {
          color: colors.$red-600;
        }

        .placeholder,
        .required {
          font-size: 12px;
          transition: font-size 0.15s ease;
        }
      }

      &::placeholder {
        opacity: 1;
      }
    }

    &.small {
      padding: 8px 12px 8px 12px;

      & + .label {
        transform: translateY(-29px) translateX(7px);
      }

      &:focus,
      &:not(:placeholder-shown) {
        & + .label {
          transform: translateY(-48px) translateX(7px);
        }
      }
    }

    &.error {
      color: colors.$red-900;
      border-color: colors.$red-600;

      &::placeholder {
        color: colors.$red-900;
      }

      & + .label {
        color: colors.$red-600;
        border-color: colors.$red-600;
      }

      &:active,
      &:focus {
        ~ .icon svg > path {
          fill: colors.$red-500;
        }
      }
    }

    &.withPrefixIcon {
      padding-left: 36px;
    }

    &.withSuffixIcon {
      padding-right: 36px;
    }
  }

  &:has(label) {
    .input {
      &::placeholder {
        opacity: 0;
      }
    }
  }
}
