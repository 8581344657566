@use '/src/components/design-system/common/colors.scss';

.container {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: colors.$gray-200;

  &__action {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: colors.$bg;
    border-radius: 10px;
    border: 1px solid colors.$gray-300;
    padding: 100px;
    gap: 25px;
  }
}
