//  GRAY COLORS
$gray-50: #f9fafb;
$gray-100: #f3f4f6;
$gray-200: #e5e7eb;
$gray-300: #d1d5db;
$gray-400: #9ca3af;
$gray-500: #6b7280;
$gray-600: #4b5563;
$gray-600-60: rgba(75, 85, 99, 0.6);
$gray-800: #1f2937;
$gray-900: #111827;

// GREEN COLORS
$green-100: #d1fae5;
$green-500: #10b981;

// TEAL COLORS
$teal-600: #0d9488;
$teal-700: #0f766e;
$teal-800: #115e59;

//BLUE COLORS
$blue-400: #60a5fa;
$blue-600: #2563eb;

// RED COLORS
$red-100: #fee2e2;
$red-400: #f87171;
$red-500: #ef4444;
$red-600: #dc2626;
$red-600-60: rgba(220, 38, 38, 0.6);
$red-800: #991b1b;
$red-900: #7f1d1d;

//YELLOW COLORS
$yellow-100: #fef3c7;
$yellow-400: #fbbf24;
$yellow-800: #92400e;

// GENERAL COLORS
$white: #ffffff;
$black: #000000;
$bg: #f4f4f6;
$light-bg: #e8e8e8;

// SHADOWS
$main-shadow: rgba(22, 22, 22, 0.05);
