@use '/src/components/design-system/common/colors';
@use '/src/components/design-system/common/mixins';

.link {
  @include mixins.hover(colors.$gray-500, colors.$white);

  &_active {
    @include mixins.hover(colors.$white, colors.$white);
  }

  svg {
    width: 100%;
    height: 100%;
    max-width: 20px;
    max-height: 20px;
  }

  &__text {
    &::first-letter {
      text-transform: uppercase;
    }

    &_active {
      font-weight: 500;
    }
  }
}
