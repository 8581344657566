@use '../common/colors.scss';

.datapicker_header {
  display: flex;
  flex-direction: row;
  gap: 4px;

  &__left,
  &__right {
    display: flex;
    flex-direction: row;
    gap: 4px;
    max-height: 28px;
    flex: 1 1 auto;
  }

  &__select {
    appearance: none;
    margin: 0;
    border: none;
    outline: none;
    font-family: 'Pragmatica-Medium';
    font-size: 14px;
    flex: 1 1 auto;
    text-align: center;
  }

  &__divider {
    width: 1px;
    height: 28px;
    background-color: colors.$gray-200;
  }

  &__arrow {
    padding: 4px;
    cursor: pointer;
    background-color: transparent;
    border: none;

    &:disabled {
      cursor: not-allowed;
    }

    path {
      fill: colors.$gray-300;
    }

    &:hover {
      path {
        fill: colors.$gray-600;
      }
    }
  }
}

:global {
  .react-datepicker {
    box-shadow: 0px 2px 6px 0px colors.$main-shadow;
    border-radius: 4px;
    border: none;
    padding: 20px 12px;
    font-size: 13px;

    &-wrapper {
      width: 100%;
    }

    &__triangle {
      &::before,
      &::after {
        border: none !important;
      }
    }

    &__header {
      border: none;
      border-radius: 0 !important;
      background-color: transparent;
      padding: 0;
      margin-bottom: 16px;
    }

    &__day-names {
      display: none;
    }

    &__month {
      margin: 0;
    }

    &__day {
      font-family: 'Pragmatica-Book';
      margin: 0;
      width: 32px;
      height: 32px;
      color: colors.$gray-600;
      padding: 8px 6px;
      border-radius: 4px;
      max-width: 32px;
      max-height: 32px;
      line-height: normal;
      font-size: 13px;

      &--outside-month {
        color: colors.$gray-200;
      }

      &--selected {
        color: colors.$teal-600;
        font-weight: 600;
        background: colors.$gray-50;

        &:hover {
          background: colors.$gray-50;
        }
      }

      &--disabled {
        color: colors.$gray-200;
        cursor: not-allowed;

        &:hover {
          color: colors.$gray-200;
        }
      }

      &--keyboard-selected {
        color: colors.$teal-600;
        background: colors.$white;

        &:hover {
          background: colors.$gray-50;
        }
      }

      &:hover:not(&--selected, &--disabled) {
        color: colors.$gray-900;
      }
    }
  }

  .react-datepicker-popper[data-placement^='bottom'] {
    padding-top: 4px !important;
    transform: translate3d(0px, 42px, 0px) !important;
  }
}

.container {
  position: relative;

  input {
    cursor: pointer;
  }

  &_disabled {
    input {
      cursor: not-allowed;

      &:hover {
        border: 1px solid colors.$gray-300 !important;
      }
    }
  }

  &:has(> div:nth-child(2)) {
    input {
      border-color: colors.$teal-600;

      & ~ div > svg > path {
        fill: colors.$teal-600;
      }

      &:not(~ span) {
        &:hover {
          border-color: colors.$teal-600 !important;
        }
      }

      &:has(~ span) {
        & ~ div > svg > path {
          fill: colors.$red-500;
        }
      }
    }
  }
}
