.form {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
  gap: 20px;
  margin: 16px 0;

  &__section {
    width: 100%;
    display: grid;
    grid-row-gap: 32px;
    grid-column-gap: 20px;
    grid-template-columns: repeat(2, 1fr);
  }

  &__add {
    height: 42px;
  }

  &__button {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
  }
}

.container {
  margin: 0 0 32px;
}
