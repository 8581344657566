@use '../common/colors.scss';
@import url('../common/fonts.scss');

.select {
  width: 100%;
  margin-bottom: 30px;
  position: relative;

  &__input {
    position: relative;
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 10px 12px;
    border: 1px solid colors.$gray-300;
    background-color: colors.$white;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    cursor: pointer;
    color: colors.$gray-300;

    &_loading {
      background-color: colors.$gray-100;
    }

    &_active {
      border: 1px solid colors.$teal-600;

      svg {
        transform: rotate(180deg);

        path {
          fill: colors.$teal-600;
        }
      }
    }

    &_error {
      border-color: colors.$red-600;
    }

    &:not(&_active, &_error):hover {
      border-color: colors.$gray-400;
    }

    &__arrow {
      max-width: 16px;
      max-height: 16px;

      svg {
        height: 16px;
        width: 16px;
        transition: transform 0.3s ease;
      }
    }

    &__value {
      opacity: 0;
      color: colors.$gray-900;
      height: 20px;
      min-width: 60px;

      &_visible {
        opacity: 1;
      }

      &_placeholder {
        opacity: 1;
        color: colors.$gray-400;
      }
    }

    &__label {
      display: flex;
      gap: 4px;
      position: absolute;
      left: 8px;
      padding: 0 4px;
      transition: transform 0.15s ease;

      span {
        transition: font-size 0.15s ease;
      }

      &_required {
        color: colors.$gray-300;
      }

      &_filled {
        color: colors.$teal-600;
        transform: translateY(-20px) translateX(0);
        background-color: colors.$white;

        span {
          font-size: 12px;
          transition: font-size 0.15s ease;
        }
      }

      &_red {
        color: colors.$red-600;
      }
    }
  }

  &__list {
    width: 100%;
    z-index: 2;
    top: 42px;
    position: absolute;
    margin-top: 4px;
    overflow: auto;
    background-color: colors.$white;
    border-radius: 4px;
    box-shadow: 0 2px 6px 0 colors.$main-shadow;
    height: auto;
    max-height: 0;

    &_open {
      max-height: 144px;
      height: auto;
      transition: max-height 0.3s ease;
    }

    &_empty {
      display: flex;
      justify-content: center;
      align-items: center;
      color: colors.$gray-400;
      min-height: 60px;
    }

    &_loading {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: max-height 0.3s ease;
    }

    &__option {
      display: flex;
      flex-direction: row;
      align-items: center;
      cursor: pointer;
      z-index: 0;
      color: colors.$gray-900;
      padding: 8px 12px;

      &:hover {
        background-color: colors.$gray-50;
      }

      &_selected {
        background-color: colors.$gray-100;
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: absolute;
    top: 11px;
    left: 12px;
    pointer-events: none;

    &_value {
      visibility: hidden;
    }

    &_search {
      left: 37px;
    }
  }

  &__error {
    color: colors.$red-600;
    position: absolute;
    top: 43px;
    left: 0;
  }
}

.sublabel {
  padding-left: 8px;
  color: colors.$gray-400;
}

.enter_active {
  max-height: 0;
}

.enter_done {
  max-height: 144px;
  transition: max-height 0.3s ease;
}

.exit {
  max-height: 144px;
  transition: max-height 0;
}

.exit_active {
  max-height: 0;
  transition: max-height 0;
}

.reset_icon {
  position: absolute;
  top: 9px;
  right: 8px;
  cursor: pointer;

  path {
    fill: colors.$gray-400;
  }

  &:hover,
  :active {
    path {
      fill: colors.$gray-500;
    }
  }
}
