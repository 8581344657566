@use '/src/components/design-system/common/colors.scss';

.employee {
  &__card {
    margin-bottom: 24px;

    &__body {
      padding: 20px 20px 0 20px;
    }

    &__general {
      display: flex;
      gap: 24px;

      &__container {
        flex: 1 1 auto;

        &__info {
          display: flex;
          gap: 24px;

          &__text {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            &__name {
              color: colors.$gray-900;
            }

            &__position {
              margin-top: 4px;
              color: colors.$gray-600;
            }

            &__office {
              color: colors.$gray-400;
            }
          }
        }
      }
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .card {
    padding: 16px;
  }

  &__table {
    height: calc(100vh - 415px);
  }
}

.modal {
  max-width: 1134px;
}
