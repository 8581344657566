@use '/src/components/design-system/common/colors.scss';

.container {
  display: flex;
  width: max-content;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 100;
  border-radius: 4px;
  padding: 3px;
  background-color: colors.$gray-400;
  pointer-events: none;
}
