@use '/src/components/design-system/common/colors.scss';

.popover {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: max-content;
  height: max-content;
  left: 60px;
  z-index: 21;
  border-radius: 4px;
  background-color: colors.$gray-800;
  color: colors.$white;

  &__children {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  span::first-letter {
    text-transform: uppercase;
  }
}
