@use '/src/components/design-system/common/colors.scss';

.content {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.form {
  display: flex;
  flex-direction: column;
  min-height: 100px;

  &__filter {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
  }

  &__content {
    display: grid;
    grid-row-gap: 2px;
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
      'a b'
      'c d'
      'e e'
      'fileUploader fileUploader';
    grid-column-gap: 20px;
    margin-top: 20px;

    &_doff {
      grid-template-areas:
        'a b'
        'c d'
        'fileUploader fileUploader';
    }

    &__fileUploader {
      grid-area: fileUploader;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}
