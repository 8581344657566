@use '../common/colors.scss';

.button {
  border: none;
  cursor: pointer;
  border-radius: 4px;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;

  .buttonIcon {
    display: flex;
    align-items: center;
    justify-content: center;

    &.primaryIcon {
      svg > * {
        fill: colors.$white;
      }
    }
  }
}

.fullWidth {
  width: 100%;
}

.withIcon {
  gap: 10.5px;
}

.large {
  padding: 8px 24px 7px 24px;
  min-width: 112px;

  .buttonIcon {
    height: 20px;
    width: 21px;
  }

  &.withIcon {
    padding: 8px 16px 7px;
  }
}

.small {
  padding: 6px 12px;
  min-width: 64px;
  height: 28px;

  .buttonIcon {
    height: 16px;
    width: 16px;
  }

  &.withIcon {
    gap: 6px;
    padding: 6px 12px 6px 8px;
  }
}

.primary {
  background-color: colors.$teal-600;
  color: colors.$white;

  &:hover {
    background-color: colors.$teal-700;
  }

  &:active {
    background-color: colors.$teal-800;
  }

  &:disabled {
    background-color: colors.$gray-200;
    cursor: not-allowed;
  }
}

.secondary {
  background-color: colors.$white;
  color: colors.$gray-500;
  border: 1px solid colors.$gray-300;

  .buttonIcon {
    svg > * {
      fill: colors.$gray-500;
    }
  }

  &:hover {
    color: colors.$gray-900;

    .buttonIcon {
      svg > * {
        fill: colors.$gray-900;
      }
    }
  }

  &:active {
    color: colors.$gray-900;

    .buttonIcon {
      svg > * {
        fill: colors.$gray-900;
      }
    }
  }

  &:disabled {
    background-color: colors.$gray-50;
    cursor: not-allowed;
  }
}

.table {
  background-color: transparent;
  color: colors.$gray-400;

  &:hover {
    color: colors.$gray-500;
  }

  &:active {
    color: colors.$gray-600;
  }
}
