@use '../common/colors.scss';

// Table styles
.container {
  border: 1px solid colors.$gray-200;
  border-radius: 4px;
  height: inherit;
  overflow: auto;

  .table {
    width: 100%;
    border-collapse: collapse;
    position: relative;

    .header {
      position: sticky;
      z-index: 10;
      top: 0;
      // display: grid;
      border-bottom: 1px solid colors.$light-bg;
      // grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      align-items: center;
      background-color: colors.$light-bg;
    }

    .body {
      .row {
        user-select: none;

        &:hover {
          background-color: colors.$gray-50;
        }
        &.hover {
          background-color: colors.$gray-50;
        }

        .action {
          cursor: pointer;
        }
      }

      .row:not(:last-child) {
        border-bottom: 1px solid colors.$gray-200;
      }
    }
  }
}

// Column header styles
.column {
  padding: 12px;
  color: colors.$gray-800;
  vertical-align: top;
  text-transform: uppercase;
  user-select: none;
  text-align: left;

  .content {
    display: flex;
    gap: 4px;

    &.sortable {
      cursor: pointer;
    }
  }

  svg {
    & > * {
      fill: colors.$gray-500;
    }
  }
}

// Cell styles
.cell {
  padding: 8px 12px;
  color: colors.$gray-600;
  word-wrap: break-word;
  vertical-align: middle;

  &.hover {
    background-color: colors.$gray-50;
  }
}

.no_data {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 60px;
}

.middle {
  vertical-align: middle;
}

.table_actions {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 12px;

  svg {
    width: 20px;
    height: 20px;
    cursor: pointer;

    path {
      fill: colors.$gray-600;
    }

    &:hover {
      path {
        fill: colors.$gray-900;
      }
    }
  }
}
