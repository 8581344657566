@use '/src/components/design-system/common/colors.scss';

.table {
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  &__button {
    display: flex;
    flex-direction: row;
    gap: 16px;
    align-items: center;
  }
}

.date {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5px;
}

.files {
  padding: 12px 0;
  display: flex;
  flex-direction: column;
  gap: 12px;

  &__container {
    padding: 4px;
    border: 1px solid colors.$gray-200;
    display: flex;
    align-items: center;
    border-radius: 4px;
    justify-content: space-between;

    &__name {
      display: flex;
      gap: 8px;
      align-items: center;

      span {
        max-width: 80px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    &__button {
      padding: 5px;

      span {
        color: colors.$blue-400;
      }
    }
  }
}
