@use '../common/colors.scss';

.spinner {
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}

.white {
  border: 2px solid colors.$white;
  border-bottom-color: transparent;
}

.gray-500 {
  border: 2px solid colors.$gray-500;
  border-bottom-color: transparent;
}

.small {
  width: 15px;
  height: 15px;
}

.medium {
  width: 48px;
  height: 48px;
}