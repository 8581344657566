@use '/src/components/design-system/common/colors';

.container {
  height: 100%;
  display: flex;
  flex-direction: column;

  &__title {
    margin-bottom: 20px;
  }

  &__card {
    flex: 1 1 auto;
    background-color: colors.$white;
    padding: 16px;
    border-radius: 10px;
    border: 1px solid colors.$gray-200;
    box-shadow: 0 2px 6px colors.$main-shadow;
    display: flex;
    flex-direction: column;
    height: 100vh;

    &__tree {
      height: 100vh;
      overflow-y: auto;
    }
  }
}
