@use '/src/components/design-system/common/colors';
@use '/src/components/design-system/common/mixins';

.list {
  &__parent {
    height: 24px;
    cursor: pointer;
    display: flex;
    column-gap: 10.5px;
    align-items: center;
    margin-bottom: 20px;

    @include mixins.hover(colors.$gray-500, colors.$white);

    &_active {
      @include mixins.hover(colors.$white, colors.$white);
    }

    svg {
      width: 100%;
      height: 100%;
      max-width: 20px;
      max-height: 20px;
    }

    &__icon {
      transition: 0.3s;

      &_rotate {
        transform: rotate(180deg);
      }
    }

    &__text {
      &::first-letter {
        text-transform: uppercase;
      }

      &_active {
        font-weight: 500;
      }
    }
  }

  &__children {
    max-height: 0px;
    transition: 0.3s;
    overflow-y: auto;
    background: colors.$gray-800;
    border-radius: 5px;
    overflow: hidden;

    &_open {
      max-height: 300px;
    }

    &__list {
      display: flex;
      flex-direction: column;

      &__item {
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding-left: 34px;
      }

      &__popover {
        padding: 10px;
      }
    }
  }
}

.enter_active {
  max-height: 0;
}

.enter_done {
  max-height: 144px;
  transition: max-height 0.3s ease;
}

.exit {
  max-height: 144px;
  transition: max-height 0;
}

.exit_active {
  max-height: 0;
  transition: max-height 0;
}
