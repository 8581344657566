@use '/src/components/design-system/common/colors.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.list {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  list-style: disc;

  &__container {
    display: flex;
    flex-direction: row;
    gap: 15px;

    &__item {
      display: flex;
      flex-direction: row;
      column-gap: 5px;
    }

    &__actions {
      display: flex;
      flex-direction: row;
      column-gap: 10px;

      svg {
        width: 25px;
        height: 25px;
        cursor: pointer;

        path {
          fill: colors.$gray-600;
        }

        &:hover {
          path {
            fill: colors.$gray-900;
          }
        }
      }
    }
  }

  &:last-child {
    padding-bottom: 50px;
  }
}

.no_data {
  display: flex;
  justify-content: center;
  align-items: center;
}
