@use '/src/components/design-system/common/colors.scss';

.form_container {
  &__heading {
    margin-bottom: 36px;
  }

  &__form {
    &__section_heading {
      display: flex;
      gap: 12px;
      margin-bottom: 32px;
      align-items: center;

      .text {
        min-width: fit-content;
        color: colors.$gray-600;
      }

      .add {
        min-width: fit-content;
      }
    }

    &__section {
      display: grid;
      grid-row-gap: 2px;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 20px;

      &:last-child {
        margin-bottom: 0;
      }

      &.education {
        grid-template-areas:
          'educationalInstitution educationalInstitution'
          'a b'
          'c d'
          'fileUploader fileUploader';

        .educationalInstitution {
          grid-area: educationalInstitution;
        }

        .fileUploader {
          grid-area: fileUploader;
        }
      }

      &.employment {
        grid-template-areas:
          'a b'
          'c d'
          'fileUploader fileUploader';

        .fileUploader {
          grid-area: fileUploader;
        }
      }
    }
  }
}

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 205px;
}
