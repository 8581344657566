@use '/src/components/design-system/common/colors.scss';

.root_boundary {
  height: 100vh;
}

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: colors.$bg;
  padding: 48px;
  
  .error_boundary {
    flex: 1 1 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
  }
}