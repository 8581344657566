@use '/src/components/design-system/common/colors';

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  &__year {
    display: flex;
    width: 100%;
    flex-direction: row;
    column-gap: 10px;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;

    &_right {
      transform: rotate(180deg);
    }

    svg {
      cursor: pointer;
      path {
        fill: colors.$gray-500;
      }

      &:hover {
        path {
          fill: colors.$gray-800;
        }
      }
    }
  }

  &__datepicker {
    display: grid;
    gap: 32px;
    grid-template-columns: repeat(3, 1fr);

    :global {
      .react-datepicker {
        background-color: colors.$gray-50;

        &__header {
          margin-bottom: 0;
        }

        &__day-names {
          display: none;
        }

        &__day {
          background-color: colors.$gray-50;
          position: relative;
          width: 40px;
          height: 40px;
          max-width: 40px;
          max-height: 40px;

          &--selected {
            font-weight: 500;
            color: colors.$gray-600;
          }

          &--disabled {
            cursor: default;
          }

          &--keyboard {
            &-selected {
              color: colors.$gray-600;
            }
          }

          &-names {
            display: flex;
            padding: 0px 4px;
          }

          &-name {
            font-weight: 500;
            font-size: 16px;
            padding: 10px;
            margin: 0 6.5px;
          }
        }
      }
    }

    &__header {
      font-weight: 500;
      font-size: 18px;
    }

    &__content {
      width: 40px;
      height: 40px;
      padding: 10px;
      position: absolute;
      font-size: 16px;

      &_selected {
        background-color: colors.$red-600-60;
        color: colors.$white;
        border-radius: 4px;
        z-index: 1;
      }
    }
  }
}
