@use '/src/components/design-system/common/colors.scss';

.page {
  min-height: 100vh;
  display: flex;
  height: 100%;

  &__container {
    width: 100%;
    display: flex;
    flex-flow: column;
    background-color: colors.$bg;
    padding-left: 240px;
    transition: padding-left 0.2s ease;

    &__header {
      width: 100%;
      position: sticky;
      top: 0;
      background-color: colors.$white;
      z-index: 19;
    }

    &__content {
      flex: 1 1 auto;
      margin: 36px;
      overflow: hidden;
    }

    &_left {
      padding-left: 55px;
    }
  }
}
