@use '../common/colors.scss';

.dropdown_button {
  position: relative;

  &__button {
    span {
      padding: 2px 18px 2px 0;
    }
  }

  &__icon {
    pointer-events: none;
    display: flex;
    gap: 8px;

    &__divider {
      border-left: 1px solid rgba(255, 255, 255, .6);
    }

    &__arrow {
      display: flex;

      &_open {
        transform: rotate(180deg);
      }
    }
  }

  .actions {
    width: 100%;
    min-width: max-content;
    right: 0;
    z-index: 11;
    position: absolute;
    margin-top: 4px;
    overflow: auto;
    background-color: colors.$white;
    border-radius: 4px;
    border: 1px solid colors.$gray-200;
    box-shadow: 0 2px 6px 0 colors.$main-shadow;
    border: 1px solid colors.$gray-200;
    height: auto;
    max-height: 0;

    &_open {
      max-height: 144px;
      height: auto;
      transition: max-height 0.3s ease;
    }

    &__action {
      cursor: pointer;
      padding: 9px 8px;
      display: flex;
      align-items: center;
      gap: 8px;

      &_icon {
        height: 16px;
        width: 16px;

        svg {
          height: 16px;
          width: 16px;
        }
      }

      &_default {
        color: colors.$gray-600;

        &:hover {
          color: colors.$gray-900;
          background-color: colors.$gray-50;
        }

        &:active {
          color: colors.$gray-900;
          background-color: colors.$gray-100;
        }
      }

      &_red {
        color: colors.$red-600;

        svg > * {
          fill: colors.$red-600;
        }

        &:hover {
          color: colors.$red-800;
          background-color: colors.$gray-50;

          svg > * {
            fill: colors.$red-800;
          }
        }

        &:active {
          color: colors.$red-800;
          background-color: colors.$gray-100;

          svg > * {
            fill: colors.$red-800;
          }
        }
      }
    }
  }
}

.enter_active {
  max-height: 0;
}

.enter_done {
  max-height: 144px;
  transition: max-height 0.3s ease;
}

.exit {
  max-height: 144px;
  transition: max-height 0;
}

.exit_active {
  max-height: 0;
  transition: max-height 0;
}
