@use '/src/components/design-system/common/colors';

.container {
  max-width: 55px;
  width: 100%;
  height: 100%;
  padding: 23px 16px 0;
  position: fixed;
  background-color: colors.$gray-800;
  transition: max-width 0.2s ease;
  z-index: 20;

  &_open {
    max-width: 240px;
    width: 240px;
  }

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 30px;
    position: absolute;
    background-color: colors.$gray-400;
    border-radius: 0 5px 5px 0;
    top: 18px;
    right: -20px;
    cursor: pointer;

    svg {
      width: 20px;
      height: 20px;

      path {
        fill: colors.$white;
      }
    }

    &:hover {
      background-color: colors.$gray-500;
      transition: 0.3s;
    }

    &_rotate {
      svg {
        transform: rotate(180deg);
      }
    }
  }

  &__links {
    overflow: hidden;
  }
}
