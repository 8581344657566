@font-face {
  font-family: 'Pragmatica-Black';
  src: local('Pragmatica-Black'), local('Pragmatica-Black'),
      url('./fonts/Pragmatica-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Pragmatica-Bold';
  src: local('Pragmatica-Bold'), local('Pragmatica-Bold'),
      url('./fonts/Pragmatica-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Pragmatica-Medium';
  src: local('Pragmatica-Medium'), local('Pragmatica-Medium'),
      url('./fonts/Pragmatica-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Pragmatica-Book';
  src: local('Pragmatica-Book'), local('Pragmatica-Book'),
      url('./fonts/Pragmatica-Book.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
