@use '../common/colors.scss';
@use '../common/mixins.scss';
@import url('../common/fonts.scss');

.container {
  position: relative;
  margin-bottom: 30px;

  .errorMessage {
    color: colors.$red-600;
    position: absolute;
    top: 100%;
    left: 0;
  }

  .label {
    position: absolute;
    top: 10px;
    left: 6px;
    transition: top 0.15s ease, left 0.15s ease;
    color: colors.$gray-300;
    background-color: colors.$white;
    padding: 0 5px 0 5px;
    display: flex;
    gap: 4px;
    pointer-events: none;

    .placeholder,
    .required {
      transition: font-size 0.15s ease;
    }

    &.error {
      color: colors.$red-600;
    }
  }

  .textarea {
    width: 100%;
    border: 1px solid colors.$gray-300;
    padding: 9px 11px 9px 11px;
    border-radius: 4px;
    background-color: colors.$white;
    font-family: Pragmatica-Book, arial;
    resize: vertical;
    min-height: 60px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.02rem;

    &::-webkit-resizer {
      background: url(./resizer.svg);
      background-repeat: no-repeat;
      background-size: 8px;
      background-position: 7px 7px;
    }

    &:focus-visible {
      outline: none;
    }

    &::placeholder {
      transition: opacity 0.15s ease-out;
      color: colors.$gray-300;
    }

    &:hover {
      border-color: colors.$gray-400;
    }

    &:active,
    &:focus {
      border-color: colors.$teal-600;
    }

    &:focus,
    &:not(:placeholder-shown) {
      & + .label {
        color: colors.$teal-600;
        top: -10px;
        left: 6px;
        background-color: colors.$white;

        &.error {
          color: colors.$red-600;
        }

        .required {
          color: colors.$red-600;
        }

        .placeholder,
        .required {
          font-size: 12px;
          transition: font-size 0.15s ease;
        }
      }
    }

    &.error {
      color: colors.$red-900;
      border-color: colors.$red-600;

      &::placeholder {
        color: colors.$red-900;
      }

      & + .label {
        color: colors.$red-600;
        border-color: colors.$red-600;
      }
    }
  }

  &:has(label) {
    .input {
      &::placeholder {
        opacity: 0;
      }

      &:focus {
        &::placeholder {
          opacity: 1;
        }
      }
    }
  }
}
