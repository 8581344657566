@use '/src/components/design-system/common/colors.scss';

.container {
  min-width: 360px;
  position: sticky;
  left: 0;
  z-index: 2;
  box-shadow: 6px 0 4px colors.$main-shadow;

  &__table {
    width: 100%;
    position: relative;

    &_border {
      border-right: 1px solid colors.$gray-300;
    }

    &__header {
      position: sticky;
      top: 0;
      align-items: center;
      background-color: colors.$light-bg;

      &__cell {
        &_border {
          &:last-child {
            .container__table__header__cell__column__title {
              border-right: none;
            }
          }
        }

        &__column {
          &__title {
            padding: 5px;
            text-transform: uppercase;
            user-select: none;
            border-right: 1px solid colors.$gray-300;
            height: 55px;
          }
        }
      }
    }

    &__body {
      &__row {
        height: 30px;
        user-select: none;
        border-bottom: 1px solid colors.$gray-300;

        &__column {
          background-color: colors.$white;

          &__text {
            border-right: 1px solid colors.$gray-300;
            padding: 4px;
            height: 30px;
          }
        }
      }

      &__no_data {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        min-height: 60px;
      }

      :last-child {
        border-bottom: none;
      }
    }
  }
}
