@use '../common/colors.scss';

.switch {
  display: flex;
  background: colors.$gray-100;
  border-radius: 4px;
  border: 1px solid colors.$gray-200;
  overflow: hidden;
  width: max-content;
  color: colors.$gray-600;
  position: relative;
  box-sizing: border-box;
  user-select: none;

  :hover {
    color: colors.$gray-900;
  }

  input[type='radio'] {
    display: none;
  }

  &__glider {
    background: colors.$white;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.06);
    height: 36px;
    position: absolute;
    bottom: 0;
    border-radius: 4px;
    z-index: 1;
    transition: transform 0.25s ease-out, width 0.2s ease-out;
    min-width: 150px;
  }

  &__tab {
    display: flex;
    align-items: center;
    justify-content: center;
    color: colors.$gray-400;
    cursor: pointer;
    transition: color 0.15s ease-in;
    padding: 8px 20px;
    min-width: 140px;

    &__label {
      z-index: 2;
    }

    &_active {
      color: colors.$gray-900;
    }
  }
}
