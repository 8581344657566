@use '/src/components/design-system/common/colors.scss';

.toast {
  display: flex;
  background-color: colors.$white;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  padding: 10px 24px;
  box-shadow: 0 2px 6px colors.$main-shadow;
  gap: 8px;

  &.error {
    background-color: colors.$red-100;

    path {
      fill: colors.$red-500;
    }
  }

  &.success {
    background-color: colors.$green-100;

    path {
      fill: colors.$green-500;
    }
  }
}