@use '/src/components/design-system/common/colors';

.payments {
  height: 100%;
  display: flex;
  flex-direction: column;

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    flex: 0 1 auto;

    &_heading {
      color: colors.$gray-900;
    }
  }

  &__body {
    flex: 1 1 auto;
    background-color: colors.$white;
    padding: 16px;
    border-radius: 10px;
    border: 1px solid colors.$gray-200;
    box-shadow: 0 2px 6px colors.$main-shadow;
    display: flex;
    flex-direction: column;
  }

  &__table__header {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
  }

  &__checkbox label {
    margin: 0;
  }

  &__buttons {
    display: flex;
    height: 38px;
    flex-direction: row;
    gap: 16px;
  }
}

.columnCheckbox label {
  margin: 0;
}
