@use '../common/colors.scss';

.stepPanel {
  padding: 32px 96px;
  border-radius: 10px;
  border: 1px solid colors.$gray-200;
  background-color: colors.$white;
  box-shadow: 0 2px 6px 0 colors.$main-shadow;
  display: flex;
  gap: 32px;
  justify-content: space-between;

  .step {
    display: flex;
    gap: 8px;
    align-items: center;

    &.pointer {
      cursor: pointer;
    }

    .stepNumber {
      width: 36px;
      height: 36px;
      padding: 8px;
      border: 1px solid colors.$gray-300;
      border-radius: 50%;
      display: flex;
      align-items: inherit;
      justify-content: center;
      color: colors.$gray-300;
      transition: color .3s ease-out, border-color .3s ease-out;
    }

    .completed {
      background-color: colors.$teal-600;

      svg > * {
        fill: colors.$white;
      }
    }

    .stepLabel {
      color: colors.$gray-400;
      transition: color .3s ease-out;
    }

    &.active {
      .stepNumber {
        color: colors.$teal-600;
        border-color: colors.$teal-600;
      }

      .stepLabel {
        color: colors.$gray-900;
      }
    }
  }
}