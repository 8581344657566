@use '/src/components/design-system/common/colors.scss';

.employee {
  .generalCard {
    margin-bottom: 24px;

    .generalCardBody {
      padding: 20px 20px 0 20px;
    }
  }

  .general {
    display: flex;
    gap: 24px;

    .infoTabsContainer {
      flex: 1 1 auto;

      .informationContainer {
        display: flex;
        gap: 24px;

        .info {
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .fullName {
            color: colors.$gray-900;
          }
          .position {
            margin-top: 4px;
            color: colors.$gray-600;
          }
          .office {
            color: colors.$gray-400;
          }
        }
      }
    }
  }
}

.generalTab {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
