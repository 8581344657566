@use '../common/colors.scss';

.card {
  background-color: colors.$white;
  border-radius: 10px;
  border: 1px solid colors.$gray-200;
  box-shadow: 0px 2px 6px 0px colors.$main-shadow;
  width: 100%; 

  .header {
    color: colors.$gray-900;
    padding: 20px 24px;
    width: 100%;
    background-color: colors.$white;
    border-bottom: 1px solid colors.$gray-200;
    border-radius:  10px 10px 0 0;
  }

  .body {
    padding: 20px 32px;
  }

  .footer {
    padding: 20px 32px;
    display: flex;
    justify-content: flex-end;
    background-color: colors.$gray-50;
    border-top: 1px solid colors.$gray-200;
    border-radius: 0 0 10px 10px;
  }
}