@use '/src/components/design-system/common/colors.scss';

.no_data {
  text-align: center;
}

.lastCard {
  margin-top: 24px;
}

.employee {
  .generalCard {
    margin-bottom: 24px;

    .generalCardBody {
      padding: 20px 20px 0 20px;
    }
  }

  .general {
    display: flex;
    gap: 24px;

    .infoTabsContainer {
      flex: 1 1 auto;

      .informationContainer {
        display: flex;
        gap: 24px;

        .info {
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .fullName {
            color: colors.$gray-900;
          }
          .position {
            margin-top: 4px;
            color: colors.$gray-600;
          }
          .office {
            color: colors.$gray-400;
          }
        }
      }
    }

    .actions {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-bottom: 20px;
      align-items: flex-end;

      .candidate {
        color: colors.$gray-600;
        background-color: colors.$gray-100;
        padding: 2px 10px 2px 8px;
        border-radius: 12px;
      }
    }
  }

  .profile {
    display: flex;
    gap: 24px;

    .firstColumn,
    .secondColumn {
      width: 50%;
      display: flex;
      flex-direction: column;
      gap: 24px;
    }
  }

  .cardContent {
    .row {
      display: grid;
      grid-template-columns: 1fr 1fr;
      padding: 12px 0;
      align-items: center;

      .title {
        color: colors.$gray-500;
      }

      .value {
        color: colors.$gray-900;
      }

      &:not(:last-child) {
        border-bottom: 1px solid colors.$gray-200;
      }
    }
  }

  .educationContent {
    .heading {
      display: flex;
      align-items: center;
      gap: 12px;
      padding: 12px 0;

      .text {
        min-width: fit-content;
      }
    }

    .files {
      padding: 12px 0;
      display: flex;
      flex-direction: column;
      gap: 12px;

      .file {
        padding: 4px;
        border: 1px solid colors.$gray-200;
        display: flex;
        align-items: center;
        border-radius: 4px;
        justify-content: space-between;

        .fileName {
          display: flex;
          gap: 8px;
          align-items: center;

          p {
            max-width: 200px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }

        .downloadBtn {
          padding: 5px;
          color: colors.$blue-400;
        }
      }
    }
  }
}

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.employmentInformation {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
