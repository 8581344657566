@use '/src/components/design-system/common/colors.scss';

.new_employee {
  &__heading {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  &__steps {
    margin-bottom: 24px;
  }

  &__form_container {
    padding: 32px 140px 48px 140px;
    border: 1px solid colors.$gray-200;
    border-radius: 10px 10px 0 0;
    background-color: colors.$white;
    box-shadow: 0 2px 6px 0 colors.$main-shadow;
  }

  &__form_buttons {
    padding: 20px 40px;
    border: 1px solid colors.$gray-200;
    border-radius: 0 0 10px 10px;
    background-color: colors.$gray-50;
    border-top: none;
    display: flex;
    justify-content: space-between;
  }
}

.summary {
  &__heading {
    margin-bottom: 36px;

    &__name {
      margin-bottom: 4px;
    }

    &__tip {
      color: colors.$gray-600;
    }
  }

  &__section {
    &__heading {
      margin-bottom: 32px;
    }
    &__content {
      margin-bottom: 36px;

      .content__row {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        padding: 12px 0;

        &__title {
          color: colors.$gray-500;
        }

        &:not(:last-child) {
          border-bottom: 1px solid colors.$gray-200;
        }
      }

      .content__heading {
        display: flex;
        gap: 12px;
        margin-bottom: 20px;
        align-items: center;

        &__name {
          min-width: fit-content;
          color: colors.$gray-600;
        }
      }
    }
  }
}