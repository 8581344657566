@use '/src/components/design-system/common/colors';

.container {
  display: flex;
  height: 100%;
  flex-direction: column;
  row-gap: 20px;

  &__table {
    flex: 1 1 auto;
    background-color: colors.$white;
    padding: 16px;
    border-radius: 10px;
    border: 1px solid colors.$gray-200;
    box-shadow: 0 2px 6px colors.$main-shadow;
    display: flex;
    flex-direction: column;
    height: 100vh;

    &__header {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 42px;
      margin-bottom: 30px;

      &__search {
        max-width: 450px;
        width: 100%;
        margin-bottom: 0;
      }

      &__year {
        display: flex;
        width: 100%;
        flex-direction: row;
        column-gap: 10px;
        justify-content: center;
        align-items: center;

        &_right {
          transform: rotate(180deg);
        }

        svg {
          cursor: pointer;
          path {
            fill: colors.$gray-500;
          }

          &:hover {
            path {
              fill: colors.$gray-800;
            }
          }
        }
      }
    }
  }
}
