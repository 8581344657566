@use '../common/colors.scss';

.container {
  position: relative;

  &__indicator {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    width: max-content;

    &:hover {
      & > span {
        color: colors.$gray-400;
      }

      & > div {
        border: 1px solid colors.$gray-400;
      }

      & > input:checked {
        & ~ div {
          background-color: colors.$teal-700;
          transition: background-color 0s ease;
        }

        &:disabled {
          & ~ div {
            background-color: colors.$teal-600;
          }
        }
      }

      & > input:disabled {
        & ~ span {
          color: colors.$gray-300;
        }

        &:not(:checked) {
          & ~ div {
            border: 1px solid colors.$gray-300;
          }
        }
      }
    }

    &__input {
      position: absolute;
      opacity: 0;
      margin: 0;

      &:checked {
        & ~ div {
          background-color: colors.$teal-600;
          border: 0;
        }
      }
    }

    &__checkbox {
      background-color: colors.$white;
      border: 1px solid colors.$gray-300;
      border-radius: 4px;
      display: flex;
      width: 16px;
      height: 16px;
      position: relative;
      transition: background-color 0.15s ease;

      &_indeterminate {
        top: 3px;
        left: 3px;
        border-radius: 2px;
        position: absolute;
        width: 8px;
        height: 8px;
        background-color: colors.$teal-700;
      }
    }

    svg {
      top: 0;
      right: 0;
      position: absolute;
      width: 16px;
      height: 16px;

      path {
        fill: colors.$gray-50;
      }
    }

    &__label {
      color: colors.$gray-300;
      display: flex;
      gap: 4px;
      padding-left: 8px;
    }

    &__required {
      font-size: 12px;
      color: colors.$red-600;
      transition: font-size 0.15s ease;
      padding-left: 4px;
    }

    &_disabled {
      cursor: not-allowed;
    }

    &_error {
      & > span {
        color: colors.$red-600;
      }

      & > div {
        border: 1px solid colors.$red-600;
      }

      &:hover {
        & > span {
          color: colors.$red-600;
        }

        & > input:not(:checked) {
          & ~ div {
            border: 1px solid colors.$red-600;
          }
        }
      }
    }
  }

  &__message {
    color: colors.$red-600;
    position: absolute;
    top: 20px;
    left: 0;
  }
}
